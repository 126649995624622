var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[(_vm.data.data.length > 0)?_vm._l((_vm.data.data),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-md-6 p-3 d-flex flex-column",class:{
						'firstBlog':i == 0 && _vm.blog,
						'without-image': !item.image && _vm.blog,
						'col-lg-4': _vm.inmueble
					}},[_c(_vm.blog ? 
							'BlogItem' :
						_vm.inmueble ?
							'InmuebleItem' :
							'',{tag:"component",attrs:{"item":item,"index":i}})],1)}):[_c('b-col',{staticClass:"p-3"},[_c('b-alert',{attrs:{"show":""}},[_c('h5',{staticClass:"text-info"},[_vm._v("No hay "+_vm._s(_vm.blog ? 'Blogs' : 'Inmuebles')+" registrados")])])],1)]],2),(_vm.pages > 1)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-auto"},[_c('b-pagination',{attrs:{"total-rows":_vm.data.total,"per-page":_vm.data.per_page,"first-number":"","last-number":""},model:{value:(_vm.pageOn),callback:function ($$v) {_vm.pageOn=$$v},expression:"pageOn"}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }