import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import AppData from '@/store/AppData'
import AppInmuebles from '@/store/AppInmuebles'
import AppBanner from '@/store/AppBanner'
import AppBlog from '@/store/AppBlog'
import AppForm from '@/store/AppForm'


const modules = {
  AppData,
  AppInmuebles,
  AppBanner,
  AppBlog,
  AppForm,
}

export default new Vuex.Store({modules});
