<template>
    <div id="InmuebleDetail">
        <HeaderPage :title="detail.titulo_inmueble" />
        <div class="container-fluid md xxl section-page">
            <div class="row">
                <div class="col-12 col-lg-8 flex-grow-1">
                    <div class="row">
                        <div v-if="detail.inmueble_imagenes.length > 0" class="col-12 mb-3">
                            <Slider :items="detail.inmueble_imagenes"></Slider>
                        </div>
                        <div v-if="width < 992" class="col-12 mb-3">
                            <MetaDataInmueble :detail="detail" />
                        </div>
                    </div>
                    <div class="row mb-2" v-if="detail.url_video">
                        <div class="col col-12">
                            <b-button size="md" class="d-flex justify-content-center" variant="danger"
                                :href="detail.url_video" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path
                                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                </svg>
                                <span class="ml-1">
                                    Ver video
                                </span>
                            </b-button>
                        </div>
                    </div>
                    <div class="row detalleInmueble">
                        <div class="col">
                            <div class="wrapperDetalleInmueble descripcionGeneral">
                                <h2 class="tituloDetalleInmueble">Descripción general</h2>
                                <div class=" textoDetalleInmueble mb-4">
                                    <p>{{ detail.descripcion }}</p>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="tableDetalleInmueble column-count">
                                            <div class="tableRow">
                                                <span class="tableLablel">Habitaciones:</span>
                                                <span class="tableValue">{{ detail.habitaciones }}</span>
                                            </div>
                                            <div class="tableRow">
                                                <span class="tableLablel">Baños:</span>
                                                <span class="tableValue">{{ detail.banos }}</span>
                                            </div>
                                            <div class="tableRow" v-if="detail.cantidad_parqueadero">
                                                <span class="tableLablel">Parqueaderos:</span>
                                                <span class="tableValue">{{ detail.cantidad_parqueadero }}</span>
                                            </div>
                                            <div class="tableRow">
                                                <span class="tableLablel">Pisos:</span>
                                                <span class="tableValue">{{ detail.pisos }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapperDetalleInmueble areaGeneral mt-5">
                                <h2 class="tituloDetalleInmueble">Area del inmueble</h2>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="tableDetalleInmueble">
                                            <div class="tableRow">
                                                <span class="tableLabel">Area Lote:</span>
                                                <span class="tableValue">{{ detail.area_lote }} <span
                                                        class="und">m<sup>2</sup></span></span>
                                            </div>
                                            <div class="tableRow">
                                                <span class="tableLabel">Area Construida:</span>
                                                <span class="tableValue">{{ detail.area_contruida }} <span
                                                        class="und">m<sup>2</sup></span></span>
                                            </div>
                                            <div class="tableRow">
                                                <span class="tableLabel">Area Total:</span>
                                                <span class="tableValue">{{ detail.area_total }} <span
                                                        class="und">m<sup>2</sup></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="tableDetalleInmueble">
                                            <div class="tableRow">
                                                <span class="tableLabel">Fondo:</span>
                                                <span class="tableValue">{{ detail.fondo }} <span
                                                        class="und">m<sup>2</sup></span></span>
                                            </div>
                                            <div class="tableRow">
                                                <span class="tableLabel">Frente:</span>
                                                <span class="tableValue">{{ detail.frente }} <span
                                                        class="und">m<sup>2</sup></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapperDetalleInmueble attrGeneral mt-5">
                                <h2 class="tituloDetalleInmueble">Caracteristicas Inmueble</h2>

                                <template v-for="feature in arrayFeatures">
                                    <div class="row  mt-4 ml-2" v-if="detail[feature.namePath].length > 0"
                                        :key="feature.id">
                                        <div class="col">
                                            <h3 class=" btn_feature" @click="handleFeature(feature)">
                                                <span>
                                                    {{ feature.nameMayus }}
                                                </span>
                                                <svg class="size_svg" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 384 512">
                                                    <path
                                                        d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            </h3>
                                            <ul class="attrList" v-show="selectFeature == feature.id">
                                                <li class="itemAttrList" v-for="item in detail[feature.namePath]"
                                                    :key="item.id">
                                                    <span class="textAttrList">
                                                        {{ item.texto }}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>

                            </div>
                            <div class="wrapperDetalleInmueble googleMaps mt-5">
                                <h2 class="tituloDetalleInmueble">Ubicación</h2>
                                <Map v-if="detail" class="flex-grow-1" v-bind="map" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 d-flex flex-column">
                    <SideBar>
                        <div class="sideBarInmueble mt-4 mt-lg-0">
                            <MetaDataInmueble v-if="width > 991" :detail="detail" />
                            <div class="formInmueble">
                                <h3 class="title-form">¿Te interesó este inmueble?</h3>
                                <Form v-bind="form" />
                            </div>
                        </div>
                    </SideBar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPage from '@/components/layout/HeaderPage'
import Slider from '../SliderInmuebleDetail'
import Map from '@/components/layout/Map'
import Form from '@/components/view/form/Form'
import MetaDataInmueble from './MetaDataInmueble'
import SideBar from '@/components/layout/SideBar'
import mixinInmuebles from '@/libs/mixins/mixinInmuebles'
import mixinUserdata from '@/libs/mixins/mixinUserdata'

export default {
    name: 'InmuebleDetail',
    mixins: [mixinInmuebles, mixinUserdata],
    components: {
        HeaderPage,
        Slider,
        MetaDataInmueble,
        Form,
        Map,
        SideBar
    },

    data() {
        return {
            visible: false,
            selectFeature: null,
            width: window.innerWidth,
            api: 'api/clientes-interested',
            overlay: false,
            arrayFeatures: [
                {
                    id: 1,
                    namePath: 'caracteristicas_internas',
                    name: 'Características internas',
                    nameMayus: 'INTERNAS'
                },
                {
                    id: 2,
                    namePath: 'caracteristicas_externas',
                    name: 'Características externas',
                    nameMayus: 'EXTERNAS'
                },
                {
                    id: 3,
                    namePath: 'caracteristicas_estado_construcion',
                    name: 'Características estado construccion',
                    nameMayus: 'ESTADO CONSTRUCCIÓN'
                },
                {
                    id: 4,
                    namePath: 'caracteristicas_tipo_fachada',
                    name: 'Características tipo fachada',
                    nameMayus: 'TIPO FACHADA'
                },
                {
                    id: 5,
                    namePath: 'caracteristicas_clasificacion',
                    name: 'Características clasificación',
                    nameMayus: 'CLASIFICACIÓN PROPIEDAD'
                },
                {
                    id: 6,
                    namePath: 'caracteristicas_orientacion',
                    name: 'Características orientación',
                    nameMayus: 'ORIENTACIÓN ZONA'
                },
                {
                    id: 7,
                    namePath: 'caracteristicas_ubicacion',
                    name: 'Características ubicación',
                    nameMayus: 'UBICACIÓN SITIOS CERCANOS'
                },
                {
                    id: 8,
                    namePath: 'caracteristicas_tarifas_adicionales_servicios_publico',
                    name: 'Características tarifas adicionales',
                    nameMayus: 'TARIFAS ADICIONALES'
                }

            ]
        }
    },
    async mounted() {
        await this.$store.dispatch('AppInmuebles/getDetailInmuebles', this.$route.params.slug);
    },
    metaInfo() {
        return {
            title: "CRMRED",
            meta: [
                { property: "og:app_id", content: '716180466259124' },
                { name: "description", content: this.shortDescription },
                { property: "og:title", content: this.detail.titulo_inmueble || "Título por defecto" },
                { property: "og:description", content: this.shortDescription },
                {
        property: "og:image",
        content: this.detail.inmueble_imagenes && this.detail.inmueble_imagenes.length > 0
          ? this.detail.inmueble_imagenes[0].url
          : "https://mi-sitio.com/imagen-por-defecto.jpg" // Imagen por defecto si no hay imágenes
      },
                { property: 'og:url', content: this.currentUrl }
            ],
        };
    },
    //  'og:app_id' => '716180466259124',
    // 'og:image' =>  $image,
    //                 'og:description' => $description,
    //                 'description' => $description,
    //                 'og:title' => $inmuebles->titulo_inmueble,
    //                 'og:site_name' => env('APP_NAME'),
    //                 'og:url' =>  env('APP_URL') . '/inmuebles/' . $inmuebles->slug . '/' . $code,
    computed: {
        detail() {
            return this.$store.state.AppInmuebles.detail || {}; // Evita errores si está vacío
        },
        currentUrl() {
            return window.location.href; // Obtiene la URL actual
        },
        shortDescription() {
            // Verifica si 'this.detail' y 'this.detail.descripcion' están definidos antes de acceder a su longitud
            const desc = this.detail && this.detail.descripcion
            ? this.detail.descripcion
            : "Descripción por defecto";

            // Si la descripción es mayor a 160 caracteres, la recorta
            return desc.length > 160 ? desc.substring(0, 157) + "..." : desc;
        },

        map() {
            const { titulo_inmueble, direccion, ciudad_id, estado_id, latitud, longitud, } = this.detail;
            return {
                nombre: titulo_inmueble,
                direccion,
                ciudad: ciudad_id.name,
                estado: estado_id.name,
                lat: latitud,
                lng: longitud,
            }
        },
        form() {
            return {
                api: 'api/clientes-interested',
                btn: { text: 'Estoy Interesado', class: 'd-block' },
                id_property: this.detail.id,
                inputs: {
                    name: {
                        value: null,
                        component: 'b-form-input',
                        attr: {
                            label: {
                                class: 'col-12',
                                label: 'Nombre completo:',
                                labelFor: '"name',
                            },
                            input: {
                                type: 'text',
                                id: 'name',
                                required: true,
                            }
                        }
                    },
                    email: {
                        value: null,
                        component: 'b-form-input',
                        attr: {
                            label: {
                                class: "col-12",
                                label: "Correo electrónico:",
                                labelFor: "email",
                            },
                            input: {
                                type: 'email',
                                id: 'email',
                                required: true,
                            }
                        }
                    },
                    cell_phone: {
                        value: null,
                        component: 'b-form-input',
                        attr: {
                            label: {
                                class: "col-12",
                                label: "Celular:",
                                labelFor: "cell_phone",
                            },
                            input: {
                                type: 'text',
                                id: 'cell_phone',
                                required: true,
                            }
                        }
                    },
                },
                modal: {
                    title: '<span class="rol">Agente inmobiliario</span>',
                    showCloseButton: true,
                    confirmButtonText: 'Cerrar',
                    html: `
							<div class="agenteInmueble d-flex flex-column align-items-center">
							<figure class="fotoAgenteInmueble">
							<img class="img-fluid" src="${this.detail.created_by.userdata.foto_persona}" alt="">
							</figure>
							<div class="nombreAgenteInmueble">
							${this.detail.created_by.userdata.primer_nombre}
							<a href="tel:${this.detail.created_by.userdata.celular_movil}" class="celular">
							<span class="icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 0H64C37.5 0 16 21.5 16 48v416C16 490.5 37.5 512 64 512h256c26.5 0 48-21.5 48-48v-416C368 21.5 346.5 0 320 0zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1zM304 384h-224V64h224V384z"/></svg>
							</span>
							<span class="label">
							${this.detail.created_by.userdata.celular_movil}
							</span>
							</a>
							<a href="https://wa.me/57${this.detail.created_by.userdata.celular_movil}" target="_blank" class="btn-wa">
							<span class="icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
							</span>
							<span class="label">
							Ir a WhatsApp
							</span>
							</a>
							</div>
							</div>
							`
                }
            }
        }
    },
    created() {
        window.onresize = () => {
            this.width = this.windowWidth();
        }
    },
    methods: {
        handleFeature(feature) {
            if (this.selectFeature == feature.id) {
                this.selectFeature = null
            } else {
                this.selectFeature = feature.id;

            }
        },
        windowWidth() {
            return window.innerWidth
        },
    }
}
</script>

<style lang="css">
.detalleInmueble {
    line-height: 1.8;
}

.btn-video {
    display: flex;
    align-items: center;
}

.tituloDetalleInmueble {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    border-bottom: 1px solid #ebe9f1;
    padding-bottom: 6px;
}

.btn_feature {
    font-size: 1rem;
    padding-bottom: 4px;
    font-weight: 700;

    border-bottom: 1px solid var(--tm-color);

    display: flex;
    justify-content: space-between;

}

.size_svg {
    height: 1rem;
    color: var(--tm-color);
    margin-right: 4px;
}

.btn_feature:hover {
    cursor: pointer;
}

.tableDetalleInmueble {
    width: 100%;
}

.tableDetalleInmueble.column-count {
    column-count: 2;
    column-gap: 30px;
}

.tableDetalleInmueble .tableRow {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
}

.tableDetalleInmueble .tableRow .tableValue:last-child {
    text-align: right;
    font-weight: 600;
}

.tableDetalleInmueble .tableRow span {
    padding: 8px 13px;
}

.tableDetalleInmueble .tableRow:nth-child(odd) {
    background: #f0f0f0;
}

.tableDetalleInmueble .tableRow:nth-child(even):last-child {
    border-bottom: 1px solid #f0f0f0;
}

.textoDetalleInmueble> :first-child {
    padding-top: 0;
    margin-top: 0;
}

.textoDetalleInmueble> :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

ul.attrList {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -8px;
}

ul.attrList li.itemAttrList {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    line-height: 1.6;
    border: 1px solid var(--tm-color);
    font-weight: 500;
    border-radius: 0.25rem;
    margin: 8px;
}

.iconAttrList {
    width: 1.25em;
    margin-right: 10px;
    font-size: 20px;
    color: var(--tm-color);
    opacity: 0.6;
}

.sideBarInmueble {
    border: 1px solid #ebe9f1;
    padding: 20px 35px;
    border-radius: 6px;
}

.title-form {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

@media(max-width: 991px) {
    .tableDetalleInmueble.column-count {
        column-count: 1;
    }
}

@media(max-width: 767px) {
    ul.attrList li.itemAttrList {
        padding: 8px 16px;
        line-height: 1.4;
        font-size: 14px;
    }
}

.titleMap {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.infoMap {
    padding: 0;
    list-style: none;
    margin: 0;
    font-size: 12px;
}

.infoMap b {
    font-weight: 500;
}

.agenteInmueble .icon {
    margin-right: 6px;
}

.agenteInmueble a.celular {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75em;
    margin-top: 8px;
    color: inherit;
}

.agenteInmueble a.btn-wa {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-size: 16px;
    background: #56d062;
    color: #fff;
    line-height: 1;
    padding: 0.4em 0.8em;
    border-radius: 0.3em;
    margin-top: 20px;
}

.agenteInmueble a.btn-wa .icon {
    font-size: 1.5em;
}

.rol {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--tm-cop);
}

.swal2-styled {
    line-height: 1;
}

.swal2-styled.swal2-confirm {
    background: var(--tm-color) !important;
}
</style>
